<template>
    <v-container fluid class="down-top-padding">
        
        <v-row >
            <v-col cols="12" md="4">
                <session-breakdown  />
            </v-col>
            <v-col cols="12" md="8">
                <active-sessions  />
            </v-col>
        </v-row>
        <BaseLoader :loading="loading"/>
    </v-container>
</template>

<script>
    import activeSessions from "./components/activeSessions.vue";
    import sessionBreakdown from "./components/sessionBreakdown.vue"
    import myPatients from "./components/myPatients"
    export default {
        name: "dashboard",
        components: {
            activeSessions,
            sessionBreakdown,
            myPatients
        },
        data: () => ({
            page: {
                title: "dashboard",
                icon: 'mdi-account'
            },
            loading: true,
            elementVisible: false,
            breadcrumbs: [
                {
                    text: "dashboard",
                    disabled: true,
                },
            ],
        }),
        computed: {
           
    }
    }
</script>
