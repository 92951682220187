<template>
    <BaseCard heading="Session Breakdown">
            <div id="chart" >
            </div>
        </BaseCard>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
import services from "@/services";

export default {
  name: "sessionBreakdown",
  components: {
    VueApexCharts,
  },
  data: () => ({
      series: [],
      timer: null,
      chart: null,
      chartOptions: {
     chart: {
            width: 380,
    type: "donut"
          },
  dataLabels: {
    enabled: false
          },
  colors: ["#4BB543", "#ffff00", "#ff9800", "#FF0000"],
  plotOptions: {
        pie: {
          donut: {
            size: "86px",
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: "18px",
                color: undefined,
                offsetY: -10,
              },
              value: {
                show: true,
                color: "#99abb4",
              },
              total: {
                show: true,
                label: "Total Session Count",
                color: "#99abb4",
              },
            },
          },
        },
          },
  legend: {
          show: true,
      position: 'bottom',
      fontFamily: 'Roboto',
        fontSize: '15px',
      fontWeight: 400,
  },
  stroke: {
        width: 1,
        colors: "rgba(0,0,0,0.1)",
      },
  grid: {
        padding: {
          left: 0,
          right: 0,
        },
        borderColor: "rgba(0,0,0,0.5)",
      },
          series: [],
  labels: ['OnTime', 'CheckDue', 'Warning', 'Late']
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 250,
            },
          },
        },
      ],
  }),
    watch: {   
  },
  computed: {
    ...mapGetters("dashboard", {
      sessionBreakdown: "sessionBreakdown",
    }),
  },
  methods: {
    async getSessionBreakdown() {
        await services.dashboardService.
        GetSessionBreakdown()
              .then((data) => {
                  var arr = [];
                  Object.keys(data.statusGroups).forEach((index) =>
                          arr.push(data.statusGroups[index].value)
                  );
          this.series = arr;
        })
        .finally(() => this.loading = false);
      },
  ready() {
      this.timer = setInterval(async function () {
      await this.getSessionBreakdown();
      this.chart.updateSeries(this.series, true);
    }.bind(this), 10000);
      }
        },
  beforeDestroy() {
      clearInterval(this.timer)
    },
  mounted: async function() {
  this.chart = new ApexCharts(document.querySelector("#chart"), this.chartOptions);
      this.chart.render();
      await this.getSessionBreakdown();
      this.chart.updateSeries(this.series, true);
      this.ready();
  },
};
</script>
