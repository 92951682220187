<template>
  <section>
    <v-toolbar style="border-bottom: 1px solid #c0c0c0">
        <v-toolbar-title>
          My Patients
        </v-toolbar-title>
          <v-spacer />
          <v-col >
          <v-text-field
            v-model="searchText"
            label="Search"
            single-line
            append-icon="mdi-magnify"
            outlined
            dense
            hide-details
            full-width
          ></v-text-field>
          </v-col>
      </v-toolbar>
    <v-card flat>
      <v-divider />
      <v-card-text>
        <v-data-table
          :headers="headers"
          single-select
          @click:row="rowClick"
          :items="items"
          :loading="loading"
          :search="searchText"
          :options="options"
          :multi-sort="true"
                :single-expand="true"
      :expanded.sync="expanded"
      show-expand
          item-key="sessionDetailId"
        >
        <template v-slot:item.techType="{ item }">
                  <v-chip :color="getTechColor(item.techType)" label>
                    <span
                      v-if="
                        item.techType && item.techType.description
                      "
                    >
                      {{ item.techType.description }}
                    </span>
                    <span v-else>
                      - -
                    </span>
                  </v-chip>
                </template>
          <template v-slot:item.lastName="{ item }">
            <span>{{ item.firstName }} {{ item.lastName }}</span>
          </template>
          <template v-slot:item.startTime="{ item }">
                  {{ getTime(item.startTime) }}
                </template>
                <template v-slot:item.endTime="{ item }">
                  {{ getTime(item.endTime) }}
                </template>
          <template v-slot:item.setupDate="props">
            {{ formatDate(props.item.setupDate) }}
          </template>
          <template v-slot:item.disconnectDate="props">
            {{ formatDate(props.item.disconnectDate) }}
          </template>
          <template v-slot:item.sessionStatusId="{ item }">
            <v-chip :color="getAlertColor(item.patientStatus)" label>
              <span v-if="item.patientStatus && item.patientStatus.description">
                {{ item.patientStatus.description }}
              </span>
              <span v-else>
                - -
              </span>
            </v-chip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          style="background-color: #eef5f9"
          class="pa-1 pt-2 elevation-0"
        >
          <v-expand-transition>
            <patient
              :myPatientId="item.patientId"
              :myOutpatientSessionId="item.outpatientSessionId"
              :key="item.outpatientSessionId"
            />
          </v-expand-transition>
        </td>
      </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
import router from "@/router";
import services from "@/services";
import patient from "./../../patient/index"
export default {
  name: "myPatientsPage",
  components: {
      patient
  },
  data: () => ({
    loading: false,
    selected: {},
    searchText: '',
    items: [],
    expanded: [],
    options: {
      groupBy: [],
      groupDesc: [],
      itemsPerPage: 100,
      multiSort: true,
      mustSort: false,
      page: 1,
      sortBy: ["startTime"],
      sortDesc: [false],
    },
    headers: [
        { text: "", value: "data-table-expand", sort: false },
      {
        text: "Unit #",
        align: "start",
        sortable: true,
        value: "unitId",
      },
      {
          text: "Job",
          align: "start",
          sortable: true,
          value: "techType"
      },
      {
        text: "Patient Name",
        align: "start",
        sortable: true,
        value: "lastName",
      },
      { text: "Setup Date", value: "setupDate", sortable: true },
      { text: "Start Time", value: "startTime", sortable: true },
      { text: "End Time", value: "endTime", sortable: true },
      { text: "Patient Status", value: "sessionStatusId", sortable: true },
    ],
    page: {
      title: "Patients",
      icon: "mdi-patient",
    },
    breadcrumbs: [
      {
        text: "Patients",
        disabled: true,
      },
    ],
    phoneMask: [
      "(",
      /\d/,
      /\d/,
      /\d/,
      ") ",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
  }),
  async mounted() {
    await this.getPatients();
  },
  methods: {
      getTime(date) {
      return this.$moment(date).format("hh:mm a");
    },
    async getPatients() {
      this.loading = true;

      await services.patientService.getMyPatients()
        .then((data) => {
          this.items = data;
        })
        .finally(() => (this.loading = false));
    },
    formatDate(date) {
      return this.$moment(date).format("MM/DD/YYYY");
    },
    rowClick: function(item, row) {
      row.select(true);
      this.selected = item;
      //router.push(`/patient/${item.patientId}`);
    },
    goToPatient(item){
        router.push(`/patient/${item.patientId}`);
    },
    normalizePhone(phone) {
      var defaultPhone = phone;
      //normalize string and remove all unnecessary characters
      phone = phone.replace(/[^\d]/g, "");

      //check if number length equals to 10
      if (phone.length == 10) {
        //reformat and return phone number
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      }

      return defaultPhone;
    },
    getAlertColor(item) {
      if (!item) return "secondary";

      var status = item.description;

      switch (status) {
        case "Setup Pending":
          return "yellow";
        case "Setup Complete":
          return "yellow";
        case "Active":
          return "primary";
        case "Disconnect Pending":
          return "error";
        case "Disconnect Complete":
          return "error";
        case "Upload & Battery Change Pending":
          return "success";
        case "Upload & Battery Change Complete":
          return "success";
        default:
          return "secondary";
      }
    },
    getTechColor(item) {
      if (!item) return "secondary";

      var status = item.description.replace("Tech", "");

      switch (status) {
        case "Pickup":
          return "error";
        case "Maintenance":
          return "info";
        case "Setup":
          return "yellow";
        case "Reapplication":
          return "accent";
        case "Upload":
          return "success";
        default:
          return "secondary";
      }
    },
  },
};
</script>
