<template>
<section id="ActiveSessions">
  <BaseCard heading="Sessions">
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchText"      
      :loading="loading"
      :single-expand="true"
      :items-per-page="30"
      :footer-props="{
        showFirstLastPage: true,
                  'items-per-page-options': [5, 10, 30, -1]
      }"
      :expanded.sync="expanded"
      show-expand
      @click:row="rowClick"
      item-key="sessionId"
    >
      <template v-slot:item.lastSessionLog=" { item }">
        <td>
        {{ formatDate(item.lastSessionLog) }}<br/>        
        </td>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          style="background-color: #eef5f9"
          class="pa-1 pt-2 elevation-543"
        >
          <v-expand-transition>
            <sessionDetail :outpatientSessionId="item.sessionId" />
          </v-expand-transition>
        </td>
      </template>
      <template v-slot:item.sessionStatus="props">
          <v-chip v-if="props.item.sessionStatus.name === 'OnTime'"
                  color="green"
                  class="white--text">
              {{ props.item.sessionStatus.name }}
          </v-chip>
          <v-chip v-if="props.item.sessionStatus.name === 'CheckDue'"
                  color="yellow"
                  class="white--text">
              {{ props.item.sessionStatus.name }}
          </v-chip>
          <v-chip v-if="props.item.sessionStatus.name === 'Warning'"
                  color="orange"
                  class="white--text">
              {{ props.item.sessionStatus.name }}
          </v-chip>
          <v-chip v-if="props.item.sessionStatus.name === 'Late'"
                  color="red"
                  class="white--text">
              {{ props.item.sessionStatus.name }}
          </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              @click="sendNotificationForSession(item)"
              v-bind="attrs"
              v-on="on"
                    :disabled="isReadOnly"
            >
              mdi-message
            </v-icon>
          </template>                
          <span>Send Notification</span>
        </v-tooltip>

         <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              @click="goToPatient(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-eye
            </v-icon>
          </template>
          <span>View Patient</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </BaseCard>
</section>
</template>

<script>
import services from "@/services";
import { ToPageModel } from "../../../modules/Models/PageModel";
import { ToSearchPageRequest } from "../../../modules/Models/SearchPageRequest";
import sessionDetail from "../../../components/sessionDetail/index";

import moment from "moment";
import router from "@/router";
export default {
        name: "activeSessions",
    components: {
            sessionDetail
        },
  data: () => ({
    totalCount: 0,
    items: [],
    loading: false,
    timer: null,
      expanded: [],
    
    searchText: "",
    options: {
      groupBy: [],
      groupDesc: [],
      itemsPerPage: 25,
      multiSort: false,
      mustSort: false,
      page: 1,
      sortBy: [],
      sortDesc: [],
    },
    headers: [
      { text: "", value: "data-table-expand", sortable: false },
      {
        text: "Unit Id",
        align: "start",
        sortable: false,
        value: "diagnosticUnitId",
      },
      { text: "Status", value: "sessionStatus", algin: "left" },
        { text: "Patient Name", value: "fullName" },
        { text: "Active Tech", value: "activeTech" },
      { text: "Incoming Tech", value: "incomingTech" },
      {
        text: "Last Session Log",
        value: "lastSessionLog",
        sortable: true,
        descending: false,
      },
      { text: "Actions", value: "actions", align: "center" },
    ],
  }),
  watch: {
    options: {
      async handler() {
        await this.getActiveSessions();
      },
      deep: true,
    },
    searchText: {
      async handler() {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(async () => {
          await this.getActiveSessions();
        }, 1000);
      },
      deep: true,
    },
    selectedStatus: {
      async handler() {
        this.options = this.defaultOptions;
        await this.getActiveSessions();
      },
      deep: true,
    },
        },
  computed: {
      isReadOnly() {
          return this.$store.state.user.isTech || this.$store.state.user.isReadOnly;
      },      
        },
  methods: {
      async getActiveSessions() {
      this.loading = true;
      var page = ToPageModel(this.options);
      var request = ToSearchPageRequest(this.searchText, page);
      await services.dashboardService
        .GetAllActiveSessions(request)
        .then((data) => {
          this.items = data.results;
          this.totalCount = data.totalCount;
        })
        .finally(()=> this.loading = false);
      },
      ready() {
    this.getActiveSessions();

        this.timer = setInterval(function () {
             var date2 = new Date()
      this.getActiveSessions();
    }.bind(this), 10000); 
      },
      cancelAutoUpdate() {
          clearInterval(this.timer)
      },
    resetExpanded(){
      this.expanded = []
    },
    rowClick: function(item, row) {
      row.select(true);
      this.selected = item;
    },
    fromNow(date) {
      if (!date) return "";
      var year = new Date(date).getFullYear();
      if (year === 1975 || year === '1975') {
        return ""
      }
      return moment.utc(date).tz('America/Chicago').fromNow();
    },
    formatDate(date) {
      if (!date) return "- -";
      var year = new Date(date).getFullYear();
      if (year === 1975 || year === '1975') {
        return "- -"
        }
        return moment.utc(date).tz('America/Chicago').format("MM/DD/YYYY H:mm");
    },
    async sendNotificationForSession(session) {
        let that = this;        
        this.loading = true;
        var status = session.sessionStatus;
        status.description = "directNotification";
        var request = {};
        request.sessionId = session.sessionId;
        request.sessionStatus = status;
        
    await services.dashboardService
        .SendNotificationForSessionId(request)
        .then(() => {
          that.$store.commit(
            "snack/set_snack",
            "Notification successfully sent."
          );
        })
        .catch((error) => {
          that.$store.commit("snack/set_snack", "Failed to send notification.");
        })
          .finally(() => {
              this.loading = false;
        });
    },
    goToPatient(item) {
router.push(`patient/${item.patientId}`)
      }
      
        },
  beforeDestroy() {
      clearInterval(this.timer)
    },
  mounted: function() {
      this.ready();
  },
};
</script>
